<template>
  <div class="ocr-area-confirm-modal with-sticky-buttons">
    <div class="heading">
      <div class="text-lg bold">Selected Area OCR</div>
      <close-icon class="close-icon" @click="closeModal"></close-icon>
    </div>
    <div class="body">
      <div class="language-block">
        <multiselect
          class="bordered ocr-type-select"
          :value="ocrType"
          placeholder=""
          :searchable="false"
          :internal-search="false"
          :clearOnSelect="false"
          :options="langOptionsAsArray"
          track-by="id"
          label="name"
          :showLabels="false"
          @select="onOcrTypeSelect"
        >
          <template v-slot:option="params">
            <span class="text-md">{{ params.option.name }}</span>
            <span v-if="params.option.tag" class="text-sm tag">{{ params.option.tag }}</span>
          </template>
        </multiselect>
        <div class="translation-block" v-if="userCanTranslate">
          <div class="text-md">Show translation</div>
          <switch-toggle
            class="small"
            :value="showTranslation"
            @toggle="toggleShowTranslation"
            v-tooltip="switchToggleTooltip"
          ></switch-toggle>
        </div>
      </div>

      <div class="result-block">
        <div class="translated-text loading supplemental" v-if="translatedTextLoading">Translating...</div>
        <div class="translated-text" v-if="translatedText && showTranslation">{{ translatedText }}</div>
        <div class="ocr-text">{{ ocrText }}</div>
        <div class="error">{{ errorText }}</div>
        <div class="loading supplemental" v-if="ocrLoading">Processing image snippet...</div>

        <div class="image-container" :style="imageContainerStyle">
          <img :src="url" :width="imageWidth" :height="imageHeight" :style="imagePreviewStyle" />
        </div>
      </div>
      <div v-if="!userCanTranslate" class="subscription-banner" @click="goToSubscriptionPlans">
        <div>
          <div class="heading-3 heading-5-mobile">Access improved text extraction models and translations!</div>
          <div class="link-text text-md">Upgrade to Detective Plan <chevron-right :size="16"></chevron-right></div>
        </div>
        <div class="icon-wrapper"><translate-variant-icon :size="66"></translate-variant-icon></div>
      </div>
    </div>

    <div class="action-buttons right-align">
      <mcr-button class="white" @click="closeModal">Close</mcr-button>
      <mcr-button class="parser-button" v-if="cacheKey" @click="openParser">
        <key-cup>T</key-cup>
        Open in parser
      </mcr-button>
      <mcr-button @click="runOcrSelection" :loading="ocrLoading">{{ okLabel }}</mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import KeyCup from '@common/elements/icons/KeyCup';
import SwitchToggle from '@common/elements/inputs/SwitchToggle';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {isChineseText} from '@common/utils/utils';
import {getOcrParserAdminUrl} from '@common/utils/utils.admin';
import {getSubscriptionWallRoute} from '@common/utils/utils.routes';
import ChevronRight from 'vue-material-design-icons/ChevronRight';
import CloseIcon from 'vue-material-design-icons/Close';
import TranslateVariantIcon from 'vue-material-design-icons/TranslateVariant';
import Multiselect from 'vue-multiselect';
import {mapGetters} from 'vuex';

const SUBSCRIBE_OPTION = 'subscribe';

export default {
  props: {
    url: String,
    imageWidth: Number,
    imageHeight: Number,
    x1: Number,
    y1: Number,
    x2: Number,
    y2: Number,
    renderScale: Number,
  },
  created() {
    document.addEventListener('keydown', this.onShortcutPress);
    if (!this.userIsSubscribedState && !this.$store.getters.subscriptionPlansState.length) {
      this.$store.dispatch('fetchSubscriptionPlansAction');
      this.$store.dispatch('fetchSubscriptionsAction');
    }
  },
  destroyed() {
    document.removeEventListener('keydown', this.onShortcutPress);
  },
  data() {
    return {
      ocrText: '',
      translatedText: '',
      translatedTextLoading: false,
      errorText: '',
      cacheKey: '',
      ocrLoading: false,
      ocrType: null,
      showTranslation: false,
    };
  },
  mounted() {
    this.setInitialOcrType();
    this.runOcrSelection();
  },
  computed: {
    ...mapGetters(['userIsStaffState', 'userIsSubscribedState', 'isTrialEligibleState', 'standardYearlyPlanState']),
    langOptions() {
      let tesseract_info = this.userIsStaffState ? ' (Tesseract)' : '';

      const defaultOptions = {
        en: 'English' + tesseract_info,
        cn: 'Chinese Autodetect' + tesseract_info,
        cn_vert: 'Chinese Vertical' + tesseract_info,
        cn_hor: 'Chinese Horizontal' + tesseract_info,
      };
      const externalOcr = {
        external_ocr: 'Google OCR',
        baidu_ocr: 'Baidu OCR',
      };
      if (this.userIsStaffState) {
        return {...externalOcr, ...defaultOptions};
      }
      if (this.userIsSubscribedState) {
        return {...externalOcr, cn: 'Basic OCR (free)'};
      }
      return defaultOptions;
    },
    langOptionsAsArray() {
      const options = Object.entries(this.langOptions).map(([id, name]) => ({id, name}));
      if (!this.userIsSubscribedState && !this.userIsStaffState) {
        const planName = this.standardYearlyPlanState ? this.standardYearlyPlanState.display_text : 'Detective';
        const tag = this.isTrialEligibleState ? 'Start a free trial' : `Upgrade to ${planName} Plan`;
        return [...options, {id: SUBSCRIBE_OPTION, name: 'Google OCR', tag}];
      }
      return options;
    },
    okLabel() {
      return this.ocrText ? 'Re-run OCR' : 'Run OCR';
    },
    left() {
      return Math.min(this.x1, this.x2);
    },
    top() {
      return Math.min(this.y1, this.y2);
    },
    snippetWidth() {
      return Math.abs(this.x1 - this.x2);
    },
    snippetHeight() {
      return Math.abs(this.y1 - this.y2);
    },
    maxImageSize() {
      const windowWidth = this.$store.getters.windowWidthState;
      if (windowWidth > 800) {
        return 600;
      }
      return windowWidth - 50;
    },
    scale() {
      if (this.snippetWidth > this.maxImageSize) {
        return this.maxImageSize / this.snippetWidth;
      }
      return 1;
    },
    imageContainerStyle() {
      let newWidth = this.snippetWidth * this.scale;
      let newHeight = this.snippetHeight * this.scale;

      return {
        width: newWidth + 'px',
        height: newHeight + 'px',
        overflow: 'hidden',
        'margin-top': '24px',
      };
    },
    imagePreviewStyle() {
      return {
        position: 'relative',
        top: `-${this.top * this.scale}px`,
        left: `-${this.left * this.scale}px`,
        maxWidth: this.imageWidth * this.scale + 'px',
        overflow: 'hidden',
      };
    },
    switchToggleEnabled() {
      return this.ocrText && isChineseText(this.ocrText);
    },
    switchToggleTooltip() {
      if (!this.switchToggleEnabled) {
        return {
          content: 'Translation is available for Chinese extracted text only',
          container: false,
          triggers: ['click', 'hover'],
        };
      }
    },
    userCanTranslate() {
      return this.userIsStaffState || this.userIsSubscribedState;
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    setInitialOcrType() {
      const paramId = this.$route.params.sourceId || this.$route.params.zupuId;
      const savedLang = paramId ? this.$store.getters.ocrLangBySourceIdState[paramId] : '';
      const source = this.$store.getters.sourceDetailsState;

      const defaultLang = paramId && source ? (source.zupu_id ? 'cn' : 'en') : 'cn';
      const premiumAccess = this.$store.getters.userIsSubscribedState || this.$store.getters.userIsStaffState;
      const defaultOCRforUser = premiumAccess ? 'baidu_ocr' : defaultLang;
      const lang = savedLang || defaultOCRforUser;
      this.ocrType = this.langOptionsAsArray.find(item => item.id === lang) || this.langOptionsAsArray[0];
    },
    openParser() {
      window
        .open(
          getOcrParserAdminUrl(
            this.$route.params.sourceId || '',
            this.$route.params.zupuId || '',
            this.$route.query.page || 1,
            this.$route.query.page_id || '',
            this.cacheKey
          ),
          '_blank'
        )
        .focus();
    },
    runOcrSelection() {
      this.ocrLoading = true;
      this.ocrText = '';
      this.translatedText = '';
      this.showTranslation = false;
      this.errorText = '';
      const params = {
        url: this.url,
        lang: this.ocrType.id,
        x1: Math.floor(Math.min(this.x1, this.x2) * this.renderScale) - 4,
        x2: Math.ceil(Math.max(this.x1, this.x2) * this.renderScale) + 4,
        y1: Math.floor(Math.min(this.y1, this.y2) * this.renderScale) - 4,
        y2: Math.ceil(Math.max(this.y1, this.y2) * this.renderScale) + 4,
      };
      this.$store
        .dispatch('runOcrForImageArea', params)
        .then(params => {
          this.ocrLoading = false;
          const fullText = params.full_text ? params.full_text.trim() : '';
          this.ocrText = fullText || 'Text not detected.';
          this.cacheKey = params.cache_key;
          AnalyticsAmplitudeHandler.trackSelectAreaOcrResultsEvent(
            this.ocrType.id,
            Boolean(fullText),
            getRoutePageName(this.$route)
          );
        })
        .catch(err => {
          this.ocrLoading = false;
          const errorText =
            err.response && err.response.data && err.response.data.detail ? err.response.data.detail[0].msg : err;
          this.errorText = 'Error during OCR: ' + errorText;
        });
    },
    onOcrTypeSelect(value) {
      if (value.id === SUBSCRIBE_OPTION) {
        this.closeModal();
        this.$router.push(getSubscriptionWallRoute(this.$route.fullPath, null));
        return;
      }
      this.ocrType = value;
      this.$store.commit('mutateOcrLangBySourceIdState', {
        id: this.$route.params.sourceId || this.$route.params.zupuId,
        lang: value.id,
      });
      this.runOcrSelection();
    },
    onShortcutPress(event) {
      if (event.key === 't' && this.cacheKey) {
        this.openParser();
      }
    },
    toggleShowTranslation() {
      if (this.switchToggleEnabled) {
        this.showTranslation = !this.showTranslation;
        if (this.showTranslation && !this.translatedText) {
          this.translatedTextLoading = true;
          this.$store
            .dispatch('translateTextAction', {text: this.ocrText})
            .then(response => {
              this.translatedText = response.translated_text;
            })
            .finally(() => {
              this.translatedTextLoading = false;
            });
        }
      }
    },
    goToSubscriptionPlans() {
      this.closeModal();
      const route = getSubscriptionWallRoute(this.$route.fullPath, null, false);
      this.$router.push(route);
    },
  },
  components: {SwitchToggle, Multiselect, McrButton, KeyCup, CloseIcon, TranslateVariantIcon, ChevronRight},
  name: 'OcrAreaConfirmModal',
};
</script>

<style lang="scss" scoped>
.ocr-area-confirm-modal {
  min-width: 800px;
  .language-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    column-gap: 16px;
    border-bottom: 1px solid $neutral-200;
    > * {
      width: 100%;
    }
    .translation-block {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: $neutral-800;
      column-gap: 16px;
    }
  }
  .result-block {
    padding: 24px;
    .translated-text {
      padding: 20px;
      font-style: italic;
      background: $neutral-100;
      margin-bottom: 24px;
    }
  }
  .ocr-type-select.multiselect {
    max-width: 400px;
    &::v-deep .multiselect__tags {
      cursor: pointer;
    }
    &::v-deep .multiselect__option {
      display: flex;
      align-items: center;
      column-gap: 12px;
      .tag {
        background: $primary-600;
        padding: 3px 8px;
        color: white;
        border-radius: 4px;
        font-weight: 800;
      }

      &.multiselect__option--highlight {
        .tag {
          background: white;
          color: $primary-600;
        }
      }
    }
  }
  .ocr-text,
  .translated-text {
    white-space: pre-wrap;
  }

  .parser-button {
    .keycup {
      margin-right: 8px;
    }
  }
  .subscription-banner {
    display: flex;
    column-gap: 16px;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding: 20px 24px;
    margin: 16px 24px 24px 24px;
    cursor: pointer;
    border-radius: 4px;
    background-image: linear-gradient($primary-200, $primary-600);
    .heading-3 {
      max-width: 575px;
      margin-bottom: 16px;
    }
    .link-text {
      display: flex;
      align-items: center;
      column-gap: 5px;
      white-space: nowrap;
    }
    .icon-wrapper {
      color: $primary-200;
    }
    &:hover {
      .link-text {
        text-decoration: underline;
      }
    }
  }
  @media only screen and (max-width: $breakpoint-tablet) {
    min-width: auto;
    .language-block {
      flex-direction: column;
      padding: 24px 16px;
      row-gap: 24px;
      .translation-block {
        justify-content: space-between;
      }
    }
    .result-block {
      padding: 24px 16px;
    }

    .subscription-banner {
      margin: 16px 16px 24px;
    }
  }
}
</style>
